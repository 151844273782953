.ghost__stores-page_container{
    padding: 2rem 2rem;
    background: #fafbff;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ghost__stores-page_title{
    padding: 2rem;
    background: #fafbff;
}

.ghost__stores-page_title h1{
    text-transform: uppercase;
    font-size: 40px;
}

.leaflet-container {
    width: 100%;
    height: 100%;
  } 

.ghost__stores-page_location-content h1{
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: 2rem;
}

.ghost__stores-page_location-content {
    flex: 1;
}

.ghost__stores-page_location-list{
    max-height: 91vh;
    overflow: hidden scroll;
}

.ghost__stores-page_map-content{
    border: 1px solid black;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    margin: 2rem;
}

.ghost__stores-page_map-container{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px){
    .ghost__stores-page_container{
        flex-direction: column-reverse;
    }

    .ghost__stores-page_map-content{
        width: 100%;
    }

    .ghost__stores-page_map-container{
        height: 300px;
    }

    .ghost__stores-page_title{
        padding: 2rem 2rem 1rem 2rem;
    }

    .ghost__stores-page_title h1{
        text-align: center;
    }
}

@media only screen and (max-width: 425px){
    .ghost__stores-heading {
        font-size: 13vw;
    }
}