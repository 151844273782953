.ghost__newsletter{
    padding: 3rem 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    background-size: cover;
    background-position: top;
}

.ghost__newsletter-container{
    background: #fafbff;
    padding: 3rem 6rem;
    border-radius: 8px;
    width: 70%;
    filter: drop-shadow(0 0 1px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ghost__newsletter-heading{
    text-transform: uppercase;
    text-align: center;
    font-size: 3rem;
    margin-bottom: .5rem;
}

.ghost__newsletter-text{
    text-align: center;
    font-family: "Roboto";
    font-size: 17px;
    margin-bottom: 1.75rem;
}

input {
    position: relative;
    display: inline-block;
    font-size: 16px;
}

input[type="text"]{
    /* height: 40px; */
    padding: 0 18px;
    font-size: 15px;
    width: 24rem;
    outline: none;
    border-radius: 3px 0 0 3px;
    border: 0.5px solid black;
    height: 40px;
}

input[type="submit"]{
    border-radius: 0 3px 3px 0;
    position: relative;
    font-size: 15px;
    left: -5px;
    background: #2489fa;
    padding: 0 20px;
    border: .5px solid black;
    outline: none;
    color: #fff;
    cursor: pointer;
    height: 40px;
}

.ghost__newsletter-container form {
    white-space: nowrap;
}

@media only screen and (max-width: 992px){
    .ghost__newsletter-container{
        width: 85%;
    }
}

@media only screen and (max-width: 870px){
    .ghost__newsletter-heading{
        font-size: 2.75rem;
    }
}

@media only screen and (max-width: 768px){
    .ghost__newsletter{
        padding: 3rem 2rem;
    }

    .ghost__newsletter-container{
        width: 90%;
        padding: 3rem 2rem;
    }
}

@media only screen and (max-width: 600px){
    .ghost__newsletter{
        padding: 3rem 1rem;
    }

    .ghost__newsletter-heading{
        font-size: 2rem;
    }

    .ghost__newsletter-container{
        padding: 2rem 1rem;
        width: 95%;
    }

    .ghost__newsletter-text{
        font-size: 13px;
    }

    input[type="text"]{
        width: 70%;
    }
}

@media only screen and (max-width: 300px){
    input[type="text"]{
        width: 7rem;
    }
}

@media only screen and (max-width: 425px){
    .ghost__newsletter-heading {
        font-size: 8vw;
    }
}