.ghost__footer{
    padding: 2rem 2rem;
}

.ghost__footer form{
    white-space: nowrap;
}

.ghost__footer_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: white;
    grid-column-gap: 2rem;
}

.ghost__footer_logo-section img{
    height: 100px;
}

.ghost__footer_heading{
    font-family: "FuturaPT-Condensed-Extra-Bold";
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: .5rem;
}

.ghost__footer_email-form input[type="text"]{
    height: 35px;
    width: 15rem;
}

.ghost__footer_email-form input[type="submit"]{
    height: 35px;
    
}

.ghost__footer_social-link{
    background: #ffff;
    width: auto;
    padding: 5px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ghost__footer_social-link-container{
    display: flex;
}

.ghost__footer_disclaimer{
    color: white;
    padding: 1rem;
}

.ghost__footer_disclaimer p{
    font-family: "Roboto";
    text-align: center;
    font-size: 12px;
    font-weight: 600;
}

.ghost__footer_email-section p{
    font-family: "Roboto";
    color: white;
    margin-bottom: .5rem;
    font-size: 14px;
}

@media only screen and (max-width: 890px){
    .ghost__footer_container{
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
}

@media only screen and (max-width: 640px){

    .ghost__footer_logo-section img{
        height: 80px
    }
    .ghost__footer_container{
        grid-template-columns: 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }

    .ghost__footer_logo-section{
        display: flex;
        justify-content: center;
    }

    .ghost__footer_email-section{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .ghost__footer_social-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ghost__footer_email-section p{
        text-align: center;
    }
}

@media only screen and (max-width: 425px){
    .ghost__footer_heading {
        font-size: 5vw;
    }
}

@media only screen and (max-width: 400px){
    .ghost__footer_email-form input[type="text"] {
        width: 10rem;
    }
}

@media only screen and (max-width: 300px){
    .ghost__footer{
        padding: 2rem 1rem;
    }

    .ghost__footer_logo-section img{
        height: 60px
    }
}