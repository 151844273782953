.ghost__featured{
    background: #ecff00;
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.ghost__featured-heading{
    text-transform: uppercase;
    font-size: 60px;
    margin-bottom: 2.5rem;
    text-align: center;
}

@media only screen and (max-width: 600px){
    .ghost__featured-heading{
        font-size: 30px;
    }
}

@media only screen and (max-width: 425px){
    .ghost__featured-heading {
        font-size: 13vw;
    }
}