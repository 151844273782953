.ghost__products{
    padding: 3rem 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.875rem 1.625rem;
}


@media only screen and (max-width: 992px){
    .ghost__products{
    grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 768px){
    .ghost__products{
    grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 768px){
    .ghost__products{
        padding: 3rem 1rem;
    }
}

@media only screen and (max-width: 268px){
    .ghost__products{
    grid-template-columns: 1fr;
    }
}