

.ghost__product{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ghost__product_name h2{
    font-family: "FuturaPT-Condensed-Extra-Bold";
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
}

.ghost__product_pack{
    font-family: "FuturaPT-Condensed-Extra-Bold";
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: .75rem;
    text-align: center;
}

.ghost__product_image img{
    height: 250px;
    margin-bottom: 1rem;
    cursor: pointer;
}

.ghost__product_options{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
}


.ghost__product_atc{
    outline: none;
    border: none;
    background: #151515;
    color: white;
    font-family: "Roboto";
    padding: .75rem 1.5rem;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
}

@media only screen and (max-width: 1120px){
    .ghost__product_tocart{
        display: none;
    }
}

@media only screen and (max-width: 992px){
    .ghost__product_name h2{
        font-size: 18px;
    }

    .ghost__product_pack{
        font-size: 15px;
    }

    .ghost__product_image img{
        font-size: 200px;
    }


}

@media only screen and (max-width: 600px){
    .ghost__product_image img{
        height: 180px;
        margin-bottom: 1rem;
    }

    .ghost__product_options{
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0rem;
        grid-row-gap: .5rem;
    }

    .ghost__product_name h2{
        font-size: 17px;
    }

    .ghost__product_pack{
        font-size: 14px;
    }

    .ghost__product_atc{
        font-size: 12px;
    }

}