.ghost__more{
    padding: 3rem 6rem;
    background: #fafbff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ghost__more-heading{
    font-family: "FuturaPT-Condensed-Extra-Bold";
    text-transform: uppercase;
    text-align: center;
    font-size: 50px;
    margin-bottom: 2.5rem;
}

@media only screen and (max-width: 768px){
    .ghost__more-heading{
        font-size: 40px;
    }

    .ghost__more{
        padding: 2rem 4rem;
    }

    
}

@media only screen and (max-width: 600px){

    .ghost__more{
        padding: 2rem 2rem;
    }

    
}