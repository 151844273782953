.ghost__more-products{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 4rem;
}

.ghost__more-products img{
    height: 250px;
}

@media only screen and (max-width: 768px){
    .ghost__more-products{
        grid-template-columns: 1fr;
        grid-column-gap: 0rem;
        grid-row-gap: 2rem;
    }
}