

.ghost__featured-product{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.ghost__featured-product_name h2{
    font-family: "FuturaPT-Condensed-Extra-Bold";
    text-transform: uppercase;
    font-size: 22px;
    text-align: center;
}

.ghost__featured-product_pack h3{
    font-family: "FuturaPT-Condensed-Extra-Bold";
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: .75rem;
    text-align: center;
}

.ghost__featured-product_image img{
    height: 390px;
    margin-bottom: 1rem;
}

.ghost__featured-product_options{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
}


.ghost__featured-product_atc{
    outline: none;
    border: none;
    background: #151515;
    color: white;
    font-family: "Roboto";
    padding: .75rem 1.5rem;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    width: 14rem;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
}

.ghost__featured-product__quantity-input{
    padding: .75rem 2rem;
    background: white;
    border-radius: 50px;
    border: 2px solid black;
    font-family: "FuturaPT-Condensed-Extra-Bold";
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 10rem;
}



@media only screen and (max-width: 830px){
    .ghost__featured-product_options{
        grid-template-columns: 1fr;
        grid-row-gap: .5rem;
    }

    .ghost__featured-product_image img{
        height: 300px;
    }

    
}
@media only screen and (max-width: 600px){
    .ghost__featured-product_image img{
        height: 200px;
        margin-bottom: 1rem;
    }

    .ghost__featured-product_options{
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0rem;
        grid-row-gap: .5rem;
    }
}