@import url("https://use.typekit.net/ira4irb.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


@font-face{
  font-family: 'FuturaPT-Condensed-Extra-Bold';
  src: local('FuturaCondensedExtraBold'),
  url("./assets/fonts/FuturaCondensedExtraBold.otf") format("opentype")
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.App{
  overflow: hidden;
}

body{
  background: #0a0e14;
}
h1 {
  font-family: "FuturaPT-Condensed-Extra-Bold";
font-weight: 800;
font-style: normal;
}