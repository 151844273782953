.ghost__pageheader{
    padding: 3rem 6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ghost__pageheader-heading{
    color: white;
    text-transform: uppercase;
    font-size: 100px;
    text-align: center;
}

@media only screen and (max-width: 992px){
    .ghost__pageheader-heading{
        font-size: 90px;
    }
}

@media only screen and (max-width: 768px){
    .ghost__pageheader-heading{
        font-size: 70px;
    }
}

@media only screen and (max-width: 600px){
    .ghost__pageheader-heading{
        font-size: 60px;
    }
}

@media only screen and (max-width: 425px){
    .ghost__pageheader-heading{
        font-size: 12vw;
    }
}