.ghost__stores{
    padding: 3rem 6rem;
    background: #ecff00;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ghost__stores-heading{
    font-size: 90px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5rem;
}

.ghost__stores-image img{
    width: 100%;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 992px){
    .ghost__stores-heading{
        font-size: 80px;
    }
}

@media only screen and (max-width: 768px){
    .ghost__stores-heading{
        font-size: 70px;
    }
}

@media only screen and (max-width: 600px){
    .ghost__stores-heading{
        font-size: 55px;
    }

    .ghost__stores{
        padding: 3rem 1rem;
    }
}