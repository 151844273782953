.ProductDetails{
    background: #ecff00;
    padding: 1rem 0 0 0; 
    overflow: hidden;
}

.ghost__product_details-grid{
    display: flex;
    flex-direction: row;
    align-content: center;
    height: auto;
    align-items: center;
    min-height: 100vh;
}

.ghost__product_details-title{
    text-transform: uppercase;
    font-size: 65px;
}

.ghost__product_details-pack{
    text-transform: uppercase;
    font-size: 30px;
    font-family: "FuturaPT-Condensed-Extra-Bold";
    margin-bottom: 2rem;
}

.ghost__product_details-media{
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.ghost__product_details-content{
    flex: 1;
    padding: 0 1rem;
}

.ghost__product_details-galImage img{
    width: 100%;
    height: auto;

}

.ghost__product_details-galImage{
    width: 100%;
    margin-bottom: -.5rem;
}

.ghost__product_details-prodImage img{
    width: auto;
    height: 500px;
    margin-bottom: 1rem;
}




.ghost__product_details-galImage:last-child{
    margin-bottom: none;
}

.ghost__product_details-desc{
    font-family: "Roboto";
    padding-right: 6rem;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 2rem;
}

.ghost__product_details-options{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.ghost__product_details-quantity{
    border: 2px solid black;
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: space-between;
    max-width: 125px;
    background: white;
    padding: .75rem;
}

.ghost__product_details-quantity input{
    width: 50%;
    font-family: "Roboto";
    font-weight: 600;
    text-align: center;
    outline: none;
    border: none;
}

.ghost__product_details-atc{
    outline: none;
    border: none;
    background: #151515;
    color: white;
    font-family: "Roboto";
    padding: .75rem 1.5rem;
    margin-left: 1rem;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    max-width: 260px;
    width: 100%;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
}

td {
    font-family: "Roboto";
    font-weight: 100;
}

@media only screen and (max-width: 1050px){
    .ghost__product_details-title{
        font-size: 52px;
    }

    .ghost__product_details-desc{
        padding-right: 5em;
    }
}

@media only screen and (max-width: 900px){
    .ghost__product_details-title{
        font-size: 46px;
    }

    .ghost__product_details-desc{
        padding-right: 3em;
    }
}

@media only screen and (max-width: 768px){
    .ghost__product_details-grid{
        flex-direction: column;
        
    }

    .ghost__product_details-media{
        width: 100%;
    }

    .ghost__product_details-content{
        padding: 0rem 4rem;
    }

    .ghost__product_details-desc{
        font-size: 15px;
        padding-right: 1em;
    }
}


@media only screen and (max-width: 600px){
    .ghost__product_details-content{
        padding: 0rem 2rem;
    }
}

@media only screen and (max-width: 460px){
    .ghost__product_details-options{
        flex-direction: column;
        align-items: center;
    }

    .ghost__product_details-quantity{
        max-width: 50%;
        margin-bottom: .5rem;
    }

    .ghost__product_details-title{
        font-size: 40px;
    }
}