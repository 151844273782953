.ghost__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
    border-bottom: 2px solid white;
    background: black;
    width: 100%;
    transition: background 5s ease;
}


.ghost__navbar.fixed{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
    border-bottom: 2px solid white;
    /* background: red; */
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
}

.ghost__navbar-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.ghost__navbar-links_logo img{
    height: 45px;
    margin-right: 2rem;
}

.ghost__navbar-links_page{
    text-decoration: none;
    color: white;
    font-family: "FuturaPT-Condensed-Extra-Bold";
    text-transform: uppercase;
    font-size: 1rem;
}

.ghost__navbar-links_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ghost__navbar-links_link{
    margin-right: 1rem;
}

.ghost__navbar-links_page:hover{
    color: #ecff00;
}

.ghost__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.ghost__navbar-menu svg{
    cursor: pointer;
}

.ghost__navbar-menu_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: end;
    background: black;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    z-index: 1000;
}

@media only screen and (max-width: 768px){
    .ghost__navbar-links_container{
        display: none;
    }

    .ghost__navbar-menu{
        display: flex;
    }
}

@media only screen and (max-width: 400px){
    .ghost__navbar-links_logo img {
        height: 35px;
    }
}